import React from "react"
import Layout from "../components/layout"
import {
  HeaderSecondary,
  ContactSection,
  DecorationSection,
  DataSection,
} from "../components/utility"

import { useStaticQuery, graphql } from "gatsby"

export default function Data() {
  const data = useStaticQuery(graphql`
    query {
      brand3: file(relativePath: { eq: "product/dataProduct.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const Product3 = [
    data.brand3.childImageSharp.fluid,
    `linear-gradient(0deg,
      rgba(0, 0, 0, 0.35),
      rgba(0, 0, 0, 0.35))`,
  ].reverse()

  return (
    <Layout>
      <HeaderSecondary
        fluid={Product3}
        title="Commander NBN"
        caption="Our Broadband NBN Bundle includes unlimited data at nbn50 speeds and a phone line with unlimited local, national and Australian mobile calls."
      />
      <DataSection />
      <ContactSection />
      <DecorationSection />
    </Layout>
  )
}
